/* eslint-disable no-console */
import { Button, CountryMobileNumber, Input, Loader } from '@storybook';
import { BodyWrapper, LabelElement } from 'components';
import {
	useNotification,
	useUrl,
	useSharedVariables,
	useNextStep,
} from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	IPhoneNumber,
	LoginPhoneNumberState,
	WebComponentMetaDataState,
} from 'states';
import { OtpVerify } from 'views';
import {
	PRIVATE_RELAY,
	QR_CONTINUE_LABEL,
	emailErrors,
	headers,
} from 'views/constants';
import { loginToasterMessages, useOtpVerify } from 'views/otp-verify/stores';
import './basic-information.scss';
import {
	AppleUserDetailsState,
	BasicInfoDataState,
	BasicInformationState,
	SessionExistState,
	useBasicInformation,
} from './store';
import { validateName } from 'utils';
import { useVerifyEmail } from 'views/email-verification/store';
import { VerifyEmail } from 'views/email-verification';
import { REACT_APP_SKIP_PHONE } from 'envs';
import EventManager from 'views/EventManager';
import { BasicInformationInputsDefaultErros } from './constants';

const dollarsInvested = { key: '0' };

interface IExtractedData {
	firstName: string | null;
	lastName: string | null;
	phone: string | null;
	countryCode: string | null;
	email: string | null;
}

const showOtpCountryList = ['+91', '+1'];
let isFormSkipped = false

export const BasicInformation = () => {
	//local state
	const { sessionPayloadDetail } = useNextStep();
	const [verificationStatus, setVerificationStatus] = useState<string>('');
	// Global state
	const [loginPhoneNumber, setLoginPhoneNumber] = useRecoilState(
		LoginPhoneNumberState
	);
	const isSessionExist = useRecoilValue(SessionExistState);

	const { isLoaded, continueInvite, abortInvite, inviteUser } =
		useBasicInformation();

	const appleUserDetails = useRecoilValue(AppleUserDetailsState);
	const { isWebComponent } = useRecoilValue(WebComponentMetaDataState);

	const [basicInfoData, setBasicInfoData] = useRecoilState(BasicInfoDataState);

	const [isModalBtnLoaded, setIsModalBtnLoaded] = useState('');
	const [payload, setPayload] = useRecoilState(BasicInformationState);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [extractedData, setExtractedData] = useState<IExtractedData>({
		firstName: null,
		lastName: null,
		phone: null,
		countryCode: null,
		email: null,
	});
	const { configManager } = useSharedVariables();

	const {
		button,
		note,
		inputStyles,
		title = 'Basic Information',
		prefilledValue,
		skipForm
	} = configManager?.pages?.['basic-details'] ?? {};

	const [errorState, setErrorState] = useState(
		BasicInformationInputsDefaultErros
	);
	const [isOtpScreen, setIsOtpScreen] = useState<boolean>(false);
	const [isEmailScreen, setIsEmailScreen] = useState<boolean>(false);
	const [statusPollingInterval, setStatusPollingInterval] = useState<ReturnType<
		typeof setInterval
	> | null>(null);
	const otpInputRef: React.RefObject<HTMLInputElement> = useRef(null);
	const firstNameInputRef: React.RefObject<HTMLInputElement> = useRef(null);

	const { code } = useUrl();
	const { getOtp } = useOtpVerify();
	const { errorNotification } = useNotification();
	const { sendEmail, getStatus } = useVerifyEmail();

	//Anuj Change : Get pre-fill data through query param for liquidity
	const extractDataFromURL = useCallback(() => {
		const currentURL = window.location.href;
		const phoneRegex = /phone=([0-9]+)/;
		const countryCodeRegex = /countryCode=([+0-9]+)/;
		const emailRegex = /email=([^&]+)/;
		const firstNameRegex = /firstName=([a-z]+)/;
		const lastNameRegex = /lastName=([a-z]+)/;

		const phoneMatch = currentURL.match(phoneRegex);
		const countryCodeMatch = currentURL.match(countryCodeRegex);
		const emailMatch = currentURL.match(emailRegex);
		const firstNameMatch = currentURL.match(firstNameRegex);
		const lastNameMatch = currentURL.match(lastNameRegex);
		const capitalizeString = (str: string) => {
			return str.charAt(0).toUpperCase() + str.slice(1);
		};

		const newData: IExtractedData = { ...extractedData };

		if (firstNameMatch) {
			newData.firstName = capitalizeString(firstNameMatch[1] ?? '');
		}

		if (lastNameMatch) {
			newData.lastName = capitalizeString(lastNameMatch[1] ?? '');
		}

		if (phoneMatch) {
			newData.phone = phoneMatch[1] ?? '';
		}

		if (countryCodeMatch) {
			newData.countryCode = countryCodeMatch[1] ?? '';
		}

		if (emailMatch?.length && emailMatch.length > 0) {
			newData.email = decodeURIComponent(emailMatch[1] ?? '');
		}

		setExtractedData(newData);
	}, [extractedData]);

	const moveOtpScreen = useCallback(() => {
		EventManager.onChange({
			key: sessionPayloadDetail.currentAction.key,
			...basicInfoData,
			...loginPhoneNumber,
			screen:"mobile-otp"
		});

		setIsOtpScreen(prev => !prev);
	}, [basicInfoData, loginPhoneNumber, sessionPayloadDetail.currentAction.key]);

	const moveToEmailScreen = useCallback(() => {
		EventManager.onChange({
			key: sessionPayloadDetail.currentAction.key,
			...basicInfoData,
			...loginPhoneNumber,
			screen:"email-verification"
		});

		setIsEmailScreen(prev => !prev);
		if (statusPollingInterval) {
			clearInterval(statusPollingInterval);
			setStatusPollingInterval(null);
		}
		setIsLoading(false);
	}, [basicInfoData, loginPhoneNumber, sessionPayloadDetail.currentAction.key, statusPollingInterval]);

	const onHandlePhoneChange = useCallback(
		({ countryCode, phone }: IPhoneNumber) => {
			setErrorState(prevState => ({
				...prevState,
				phone: {
					isError: false,
					message: '',
				},
			}));
			setLoginPhoneNumber((prevState: IPhoneNumber) => {
				const newState = {
					...prevState,
					countryCode: countryCode,
					phone: phone.replace(/\D/g, ''),
				};
				return newState;
			});
		},
		[setLoginPhoneNumber]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number) => {
			setLoginPhoneNumber((prevState: IPhoneNumber) => {
				const newState = {
					...prevState,
					countryCode: countryCode,
				};
				return newState;
			});
		},
		[setLoginPhoneNumber]
	);

	useEffect(() => {
		extractDataFromURL();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isPrivateRelay = useCallback((value: string) => {
		return value?.toLowerCase().includes(PRIVATE_RELAY);
	}, []);

	// Cleanup on component unmount
	useEffect(() => {
		return () => {
			if (statusPollingInterval) {
				clearInterval(statusPollingInterval);
			}
		};
	}, [statusPollingInterval]);

	const handleValidation = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	const handleSubmit = useCallback(async () => {
		if (!basicInfoData?.firstName?.trim() || !basicInfoData?.lastName?.trim()) {
			errorNotification(loginToasterMessages.InvalidInput);
			return;
		}
		setIsLoading(true);
		setVerificationStatus('');
		if (isLoaded) {
			setBasicInfoData({ ...basicInfoData, ...loginPhoneNumber });
			const users = {
				headers: headers,
				rows: [
					Object.values({
						...basicInfoData,
						...loginPhoneNumber,
						...dollarsInvested,
					}),
				],
			};
			setPayload({
				users,
				qrId: code,
			});

			EventManager.onChange({
				key: sessionPayloadDetail.currentAction.key,
				...basicInfoData,
				...loginPhoneNumber,
			});

			const checking: any = await EventManager.waitForMessageEvent(
				'FETCH_TRIGGER',
				3000
			);
			if (checking.isError) {
				setIsLoading(false);
				setErrorState({
					...errorState,
					...checking.error,
				});
				return;
			}

			const checkedValidCountryOtp =
				showOtpCountryList.includes(appleUserDetails.countryCode) ||
				showOtpCountryList.includes(loginPhoneNumber.countryCode.toString());
			// Awadhesh: hide otp page for other countries
			if (appleUserDetails.phone?.length || !checkedValidCountryOtp) {
				await inviteUser({
					users,
					qrId: code,
				});
			} else {
				if (REACT_APP_SKIP_PHONE) {
					const resp = await sendEmail(
						basicInfoData?.firstName,
						basicInfoData?.email
					);
					if (resp?.statusCode === 200) {
						const statusId = resp?._id ?? '';
						moveToEmailScreen();
						// Start polling for status
						const intervalId = setInterval(async () => {
							const statusResponse = await getStatus(statusId);
							if (statusResponse?.statusCode === 200) {
								const { approvedStatus: status } = statusResponse ?? {}; // Assuming the response contains a 'status' field
								if (status === 'approved') {
									clearInterval(intervalId); // Stop polling
									// You can handle the final status here if needed
									otpInputRef.current?.focus();
									const resp = await getOtp();
									if (resp?.statusCode === 200) {
										moveToEmailScreen();
										moveOtpScreen();
									}
									setIsLoading(false);
								} else if (status === 'rejected') {
									clearInterval(intervalId); // Stop polling
									setVerificationStatus(status);
									setIsLoading(false);
								}
							}
						}, 2000);
						setStatusPollingInterval(intervalId); // Store the interval ID
					}
				} else {
					await inviteUser({
						users,
						qrId: code,
					});
				}
			}
		}
	}, [
		basicInfoData,
		isLoaded,
		errorNotification,
		setBasicInfoData,
		loginPhoneNumber,
		setPayload,
		code,
		appleUserDetails.countryCode,
		appleUserDetails.phone?.length,
		inviteUser,
		getOtp,
		moveOtpScreen,
		sendEmail,
		moveToEmailScreen,
		getStatus,
		sessionPayloadDetail,
		errorState,
	]);
	
	useEffect(() => {
		const {countryCode, phone} = prefilledValue?.phone ?? {} 
		setBasicInfoData(prev => {
			const {
				firstName: extractedFirstName,
				lastName: extractedLastName,
				email: extractedEmail,
			} = prefilledValue ?? extractedData
			const {
				firstName: appleFirstName,
				lastName: appleLastName,
				email: appleEmail,
			} = appleUserDetails;

			return {
				...prev,
				firstName: extractedFirstName || appleFirstName || prev.firstName || '',
				lastName: extractedLastName || appleLastName || prev.lastName || '',
				email:
					[extractedEmail, appleEmail, prev.email].find(
						email => email && !isPrivateRelay(email)
					) || '',
			};
		});
		setLoginPhoneNumber((prevState: any) => {
			const newState = {
				...prevState,
				countryCode: countryCode ? countryCode : extractedData.countryCode
					? extractedData.countryCode
					: appleUserDetails.countryCode ?? prevState.countryCode ?? '',
				phone: phone ? phone : extractedData.phone
					? extractedData.phone
					: appleUserDetails.phone ?? prevState.phone ?? '',
			};
			return newState;
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appleUserDetails, extractedData ,prefilledValue]);

	useEffect(()=>{
		if(skipForm && basicInfoData.email && !isFormSkipped){
			isFormSkipped = true
			setTimeout(()=>{
				handleSubmit()
			},100)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[basicInfoData , skipForm])

	const handleContinue = useCallback(async () => {
		setIsModalBtnLoaded(QR_CONTINUE_LABEL.old);
		await continueInvite();
	}, [continueInvite]);

	const handleNew = useCallback(async () => {
		setIsModalBtnLoaded(QR_CONTINUE_LABEL.new);
		await abortInvite(payload);
	}, [abortInvite, payload]);

	const validateEmail = useCallback(
		(emailString: string) => {
			const trimmedEmail = emailString.trim();
			const emailRegex =
				// eslint-disable-next-line no-useless-escape
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (isPrivateRelay(trimmedEmail)) {
				return true;
			} else if (emailRegex.test(trimmedEmail) || trimmedEmail.length === 0) {
				return false;
			} else {
				return true;
			}
		},
		[isPrivateRelay]
	);

	const nextButtonLabel = useMemo(() => {
		if (button?.primary) {
			return button.primary;
		}
		if (isLoaded) {
			return 'Next';
		}
		return !isModalBtnLoaded.length ? (
			<Loader type="circle" dimension={24} className="loader-white" />
		) : (
			'Next'
		);
	}, [isLoaded, isModalBtnLoaded.length, button]);

	const isDisable = useMemo(() => {
		const validBasicInfo =
			Object.values({ ...basicInfoData, ...loginPhoneNumber })?.every(
				(value: any) => value?.trim().length
			) &&
			loginPhoneNumber.phone.length > 2 &&
			!validateEmail(basicInfoData.email) &&
			validateName(basicInfoData.firstName) &&
			validateName(basicInfoData.lastName);
		return validBasicInfo;
	}, [basicInfoData, loginPhoneNumber, validateEmail]);

	const handleError = useCallback(
		(key: string) => {
			switch (key) {
				case 'firstName':
				case 'lastName':
					setBasicInfoData(prev => ({ ...prev, [key]: prev[key]?.trim() }));
					setErrorState(prevState => ({
						...prevState,
						[key]: {
							isError: !validateName(basicInfoData[key]?.trim()),
							message:
								key === 'firstName'
									? 'First name is invalid'
									: 'Last name is invalid',
						},
					}));
					break;
				case 'email':
					setErrorState(prevState => ({
						...prevState,
						[key]: {
							isError: validateEmail(basicInfoData.email),
							message: 'Email is invalid',
						},
					}));

					break;
				case 'phone':
					setErrorState(prevState => ({
						...prevState,
						[key]: {
							isError: loginPhoneNumber.phone.length < 6,
							message: 'Phone number is invalid',
						},
					}));

					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[basicInfoData, loginPhoneNumber.phone]
	);

	const handleInfoChange = useCallback(
		(value: string, key: string) => {
			setErrorState(prevState => ({
				...prevState,
				[key]: {
					isError: false,
					message: '',
				},
			}));
			if (isPrivateRelay(value)) {
				setErrorState(prevState => ({
					...prevState,
					[key]: {
						isError: true,
						message: emailErrors.PRIVATE_RELAY_ERROR,
					},
				}));
			}
			setBasicInfoData(prevState => ({
				...prevState,
				[key]: value,
			}));
		},
		[isPrivateRelay, setBasicInfoData]
	);

	const bodyElement = useMemo(
		() => (
			<div className="basic_info_container">
				{note && (
					<div className="basic_info_container__title">
						<h4>
							{
								<div className="basic_info_container__note">
									<div className="basic_info_container__note__title">
										<i className="ri-alarm-warning-line basic_info_container__note__icon"></i>
										<span>
											Note:{' '}
											<span className="basic_info_container__note__text">
												{note}
											</span>
										</span>
									</div>
								</div>
							}
						</h4>
					</div>
				)}

				<div className="input_div_container">
					<Input
						inputType="text"
						placeholder="First name"
						value={basicInfoData?.firstName}
						styles={inputStyles?.firstName}
						label="First Name"
						inputRef={firstNameInputRef}
						isRequired={true}
						handleChange={e => handleInfoChange(e.target.value, 'firstName')}
						handleBlur={() => handleError('firstName')}
						isError={errorState.firstName.isError}
						errorMessage={errorState.firstName.message}
						disabled={isWebComponent && !!appleUserDetails?.firstName}
					/>
					<Input
						inputType="text"
						placeholder="Last name"
						value={basicInfoData?.lastName}
						styles={inputStyles?.lastName}
						label="Last Name"
						isRequired={true}
						handleChange={e => handleInfoChange(e.target.value, 'lastName')}
						handleBlur={() => handleError('lastName')}
						isError={errorState.lastName.isError}
						errorMessage={errorState.lastName.message}
						disabled={isWebComponent && !!appleUserDetails?.lastName}
					/>
				</div>
				<div className="basic_info_input">
					<CountryMobileNumber
						handleChange={onHandlePhoneChange}
						defaultCountryCode={loginPhoneNumber.countryCode}
						defaultNumber={loginPhoneNumber.phone}
						handleChangeCountry={onHandleChangeCountry}
						phoneNumberDisabled={appleUserDetails.phone || extractedData.phone}
						handleBlur={() => handleError('phone')}
						isError={errorState.phone.isError}
						errorMessage={errorState.phone.message}
						isRequired
						handleValidation={handleValidation}
						autoComplete={
							appleUserDetails.phone || extractedData.phone
								? 'new-password'
								: 'on'
						}
						isReadOnly={appleUserDetails.phone || extractedData.phone}
					/>
				</div>

				<div
					className="basic_info_input basic_info_input--email basic_info__margin"
					onKeyDown={e => {
						if (e?.key === 'Enter' && isPhoneValid && isDisable && !isLoading) {
							e?.preventDefault();
							handleSubmit(); // Trigger submit when Enter key is pressed
						}
					}}
					tabIndex={0}
				>
					<Input
						inputType="text"
						placeholder="Email address"
						autoComplete={
							(appleUserDetails.email &&
								!/@privaterelay/.test(appleUserDetails.email)) ||
							extractedData.email
								? 'new-password'
								: 'on'
						}
						styles={inputStyles?.email}
						isError={errorState.email.isError}
						errorMessage={errorState.email.message}
						value={basicInfoData?.email}
						disabled={
							(appleUserDetails.email &&
								!/@privaterelay/.test(appleUserDetails.email)) ||
							extractedData.email
						}
						handleChange={e => handleInfoChange(e.target.value, 'email')}
						label="Email"
						isRequired={true}
						handleBlur={() => handleError('email')}
						readOnly={
							(appleUserDetails.email &&
								!/@privaterelay/.test(appleUserDetails.email)) ||
							extractedData.email
						}
					/>
				</div>
				<div className="basic_info_button">
					<Button
						type={'button__filled--primary button__large button__block'}
						handleClick={handleSubmit}
						label={
							isLoading ? (
								<Loader type="loader" dimension={20} />
							) : (
								nextButtonLabel
							)
						}
						disabled={!isPhoneValid || !isDisable || isLoading}
					/>
				</div>
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			appleUserDetails.firstName,
			appleUserDetails.lastName,
			appleUserDetails.email,
			loginPhoneNumber.countryCode,
			loginPhoneNumber.phone,
			basicInfoData,
			onHandlePhoneChange,
			onHandleChangeCountry,
			handleError,
			handleSubmit,
			nextButtonLabel,
			isDisable,
			isLoading,
			errorState,
			configManager,
		]
	);

	const existBodyElementScreen = useMemo(() => {
		return (
			<div
				className={`basic_info_container ${
					!(
						(isModalBtnLoaded === QR_CONTINUE_LABEL.old ||
							isModalBtnLoaded === QR_CONTINUE_LABEL.new) &&
						isLoaded
					)
						? ''
						: 'basic_info_container__disabled'
				}`}
			>
				<div>
					<h4>{QR_CONTINUE_LABEL.header} </h4>
					<p className="basic_info_para2">{QR_CONTINUE_LABEL.subHeader}</p>
				</div>
				<div
					className="input_div_container"
					onKeyDown={() => ({})}
					onClick={handleContinue}
				>
					<div className="session-wrapper">
						<div className="session-wrapper__session-icon">
							<i className="session-wrapper__left-icon ri-contacts-fill" />
						</div>
						<div className="session-wrapper__session_text">
							<p className="session_text__1">{QR_CONTINUE_LABEL.old}</p>
							<p className="session_text__2">
								{QR_CONTINUE_LABEL.old_description}
							</p>
						</div>
						<div className="session-wrapper__right-icon-wrapper">
							{!(isModalBtnLoaded === QR_CONTINUE_LABEL.old && isLoaded) ? (
								<i className="session-wrapper__right-icon ri-arrow-right-s-line" />
							) : (
								<Loader type="circle" dimension={16} className="loader-white" />
							)}
						</div>
					</div>
				</div>
				<div
					className="input_div_container"
					onKeyDown={() => ({})}
					onClick={handleNew}
				>
					<div className="session-wrapper">
						<div className="session-wrapper__session-icon">
							<i className="session-wrapper__left-icon ri-user-add-fill" />
						</div>
						<div className="session-wrapper__session_text">
							<p className="session_text__1">{QR_CONTINUE_LABEL.new}</p>
							<p className="session_text__2">
								{QR_CONTINUE_LABEL.new_description}
							</p>
						</div>
						<div className="session-wrapper__right-icon-wrapper">
							{!(isModalBtnLoaded === QR_CONTINUE_LABEL.new && isLoaded) ? (
								<i className="session-wrapper__right-icon ri-arrow-right-s-line" />
							) : (
								<Loader type="circle" dimension={16} className="loader-white" />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}, [handleContinue, isModalBtnLoaded, isLoaded, handleNew]);

	const style = useMemo(() => {
		if (!isOtpScreen) {
			return {
				opacity: 0,
				height: 1,
				width: 1,
				overflow: 'hidden',
			};
		}
		return {};
	}, [isOtpScreen]);

	// Shahbaaz: If user want to continue existing flow
	if (isSessionExist) {
		return (
			<div className="terms_header_container">
				<BodyWrapper
					label={
						<div className="terms_header">
							<LabelElement text={'Basic Information'} />
						</div>
					}
					bodyElement={existBodyElementScreen}
				/>
			</div>
		);
	}

	return (
		<div className="basic-info--wrapper">
			{!isEmailScreen && !isOtpScreen && (
				<BodyWrapper
					label={
						<div className="terms_header">
							<LabelElement text={title} />
						</div>
					}
					bodyElement={bodyElement}
				/>
			)}

			{isOtpScreen && (
				<div style={style}>
					<OtpVerify
						isOtpScreen={isOtpScreen}
						otpInputRef={otpInputRef}
						moveOtpScreen={moveOtpScreen}
					/>
				</div>
			)}
			{isEmailScreen && (
				<div className="verify-email-container">
					<VerifyEmail
						moveToEmailScreen={moveToEmailScreen}
						verificationStatus={verificationStatus}
						hideBackButton={!!configManager.hideBackButton}
					/>
				</div>
			)}
		</div>
	);
};
