import { Button, ReactDropdown } from '@storybook';
import { useNextStep } from 'hooks';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	CameraOptions,
	IsFaceMeshLoadedState,
	activeScreenState,
	cameraPermissionDisplayMessage,
	isCameraPermission,
	isRetakeCameraState,
	selectedCameraLabel,
	selectedDeviceIdState,
	videoRefAtom,
} from 'views/facial-enroll/states';
import { selfieImageURL } from 'views/facial-enroll/states/selfie-image-state';
import { IsValidSelfie, SessionIdComplex } from 'views/selfie-camera/store';
import { useFace } from '../../hooks/use-face';
import { CanvasWrapper } from './canvas';
import { FaceSetupModal } from './face-setupModel';
import './facial-enroll.scss';
import { checkIsImageBlank } from 'utils/canvasUtils';
import { CameraPermisionsErrors } from 'views/facial-enroll/constant';
import {
	PalmEnrollmentEnabledState,
	boundingBoxCheck,
	faceEnrollmentEnabledState,
} from 'hooks/use-next-step/stores';
import { IOptionType } from 'views/kyb/stores';
import { AccessTokenState, AndroidVersionInfoState } from 'states';
import { useAgoraVideoStream } from 'views/facial-enroll/hooks';
import useRealtimeDatabase from 'utils/useRealtimeDatabase';
import { AgoraStreamManager } from 'utils';

const optionCss = {
	baseStyle: {
		display: 'flex',
		alignItems: 'center', // Align items to the start vertically
		justifyContent: 'flex-start',
		paddingTop: 6,
	},
	hoverStyle: {
		borderRadius: 8,
		transform: 'scale(0.98)', // Slightly reduce the size
	},
};

export const FaceEnroll: FC = () => {
	const { start } = useFace();
	const {
		publish,
		getTokenAndInitAgora,
		startProcessingVideo,
		isPublishingStart,
	} = useAgoraVideoStream();
	const androidVersionInfo = useRecoilValue(AndroidVersionInfoState);
	const moveRightRef = useRef<boolean>(false);
	const moveLeftRef = useRef<boolean>(false);
	const [, forceUpdate] = useState<any>(null);
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const setSelfieimageURL = useSetRecoilState(selfieImageURL);
	const setIsValidSelfie = useSetRecoilState(IsValidSelfie);
	const { sessionPayloadDetail } = useNextStep();
	const { sessionId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const setSessionIdComplex = useSetRecoilState(SessionIdComplex);
	const setCameraPermissionMessage = useSetRecoilState(
		cameraPermissionDisplayMessage
	);
	const [isFaceMeshModelLoaded, setFaceMeshModelLoaded] = useRecoilState(
		IsFaceMeshLoadedState
	);
	const isRetakeCamera = useRecoilValue(isRetakeCameraState);
	const setCameraPermission = useSetRecoilState<boolean | null>(
		isCameraPermission
	);
	const faceEnrollmentCheck = useRecoilValue(faceEnrollmentEnabledState);
	const palmEnrollmentCheck = useRecoilValue(PalmEnrollmentEnabledState);
	const [cameraOptions] = useRecoilState(CameraOptions);
	const [valueCamera, setValueCamera] = useState<any>();
	const [, SetselectedDeviceIdState] = useRecoilState(selectedDeviceIdState);
	const [, SetselectedCameraLabel] = useRecoilState(selectedCameraLabel);
	const [deFaultvalueCamera, setdeFaultValueCamera] = useState<IOptionType>();
	const [boundingBoxboolean, setBoundingBoxboolean] =
		useRecoilState<boolean>(boundingBoxCheck);
	const videoRef = useRecoilValue(videoRefAtom);
	const agoraVideoRef = useRef<HTMLVideoElement | null>(null);
	const { code } = useRecoilValue(AccessTokenState);
	const usersLiveNessInfo = useRealtimeDatabase<Record<string, any>>(
		code ?? ''
	);
	const cameraId = useRecoilValue(selectedDeviceIdState);
	const [, setRerender] = useState(0);

	const localVideoTrack = useMemo(
		() => AgoraStreamManager.getStream(cameraId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cameraId, isPublishingStart]
	);

	useEffect(() => {
		if (
			usersLiveNessInfo?.liveness_detection === 'Face Left' &&
			moveRightRef.current
		) {
			moveLeftRef.current = true;
			setBoundingBoxboolean(true);
			setRerender(prev => prev + 1); // Trigger re-render
		} else if (usersLiveNessInfo?.liveness_detection === 'Face Right') {
			moveRightRef.current = true;
			setRerender(prev => prev + 1); // Trigger re-render
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usersLiveNessInfo]);

	useEffect(() => {
		if (
			localVideoTrack?.videoTrack !== null &&
			localVideoTrack?.videoTrack !== undefined
		) {
			setFaceMeshModelLoaded(true);
			startProcessingVideo();
		}
		if (
			!localVideoTrack ||
			!localVideoTrack.videoTrack ||
			!agoraVideoRef.current
		) {
			setRerender(prev => prev + 1); // Trigger re-render
			return;
		}

		// Get the media stream track from Agora
		const mediaStreamTrack = localVideoTrack.videoTrack.getMediaStreamTrack();
		const mediaStream = new MediaStream([mediaStreamTrack]);

		// Set the stream as the source for our video element
		agoraVideoRef.current.srcObject = mediaStream;
		// Apply CSS to flip the video
		agoraVideoRef.current.style.transform = 'scaleX(-1)';
		agoraVideoRef.current.style.webkitTransform = 'scaleX(-1)'; // For Safari

		const updateVideoSize = () => {
			if (agoraVideoRef.current) {
				agoraVideoRef.current.style.width = `${window.innerWidth}px`;
				agoraVideoRef.current.style.height = `${window.innerHeight}px`;
			}
		};
		updateVideoSize();

		// Listen for window resize events
		window.addEventListener('resize', updateVideoSize);
		// Play the video
		agoraVideoRef.current
			.play()
			// eslint-disable-next-line no-console
			.catch(err => console.error('Video play error:', err));
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		cameraId,
		androidVersionInfo?.isBelowAndroid10,
		localVideoTrack?.videoTrack,
	]);

	useEffect(() => {
		const element = document.querySelector(
			'.skelton-wrapper__body'
		) as HTMLElement | null;
		let currentBorder = '';
		if (element) {
			currentBorder = element.style.border;
			element.style.border = 'unset';
		}
		return () => {
			if (element) {
				element.style.border = currentBorder;
			}
		};
	}, []);

	useEffect(() => {
		const defaultValueObject = {
			value: cameraOptions[0]?.value ?? '',
			label: cameraOptions[0]?.label ?? '',
		};
		setdeFaultValueCamera(defaultValueObject);
	}, [cameraOptions]);

	const onResults = useCallback(
		(results?: any) => {
			if (moveRightRef.current && moveLeftRef.current) {
				return;
			}

			results?.faceBlendshapes?.[0]?.categories?.forEach((shape: any) => {
				if (
					(shape.displayName || shape.categoryName) === 'eyeLookInRight' &&
					shape.score > 0.65
				) {
					moveRightRef.current = true;
				}

				if (
					(shape.displayName || shape.categoryName) === 'eyeLookInLeft' &&
					shape.score > 0.45 &&
					moveRightRef.current
				) {
					moveLeftRef.current = true;
				}
			});
			if (moveRightRef.current || moveLeftRef.current) {
				forceUpdate({});
			}
		},
		[moveRightRef, moveLeftRef]
	);

	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	const stopCamera = () => {
		if (videoRef instanceof HTMLVideoElement) {
			const stream = videoRef.srcObject;

			if (stream instanceof MediaStream) {
				const tracks = stream.getTracks();

				tracks.forEach(track => {
					track.stop();
				});

				videoRef.srcObject = null;
			}
		}
	};

	useEffect(() => {
		if (isRetakeCamera) {
			moveLeftRef.current = true;
			moveRightRef.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRetakeCamera]);

	useEffect(() => {
		const video = document.getElementById('webcam') as HTMLVideoElement;
		const canvasElement = document.getElementById(
			'output_canvas'
		) as HTMLCanvasElement;
		// if (isRetakeCamera && androidVersionInfo.isBelowAndroid10) {
		// 	// publish();
		// 	return;
		// }
		if (androidVersionInfo.isBelowAndroid10 && !isRetakeCamera) {
			getTokenAndInitAgora().then(resp => {
				if (resp?.videoTrack) {
					publish(resp?.videoTrack);
				}
			});
		} else {
			if (androidVersionInfo.isBelowAndroid10) {
				return;
			}
			start({
				video,
				canvas: canvasElement,
				onResults,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		androidVersionInfo,
		isRetakeCamera,
		moveRightRef,
		moveLeftRef,
		onResults,
	]);

	const onCaptureselfie = async () => {
		if (androidVersionInfo.isBelowAndroid10) {
			const agoraVideo = agoraVideoRef.current;
			if (agoraVideo) {
				// Create a hidden canvas
				const canvas = document.createElement('canvas');
				canvas.width = agoraVideo.videoWidth;
				canvas.height = agoraVideo.videoHeight;
				const context = canvas.getContext('2d');

				if (context) {
					// Draw the current video frame to the canvas
					context.drawImage(agoraVideo, 0, 0, canvas.width, canvas.height);

					// Convert the canvas content to a Base64 image URL
					const imageURL = canvas.toDataURL('image/jpeg');

					setSelfieimageURL(imageURL);

					// Check the length of the Base64 string
					if (imageURL.length > 15) {
						setActiveScreen('selfie-screen');
						setFaceMeshModelLoaded(false);
					}
				} else {
					// eslint-disable-next-line no-console
					console.error('Failed to get canvas context');
				}
			}
			return;
		} else {
			const canvas = document.getElementById(
				'output_canvas'
			) as HTMLCanvasElement;
			const video = document.getElementById(
				androidVersionInfo.isBelowAndroid10 ? 'agroa-enroll' : 'webcam'
			) as HTMLVideoElement;

			const context = canvas.getContext('2d');
			context?.drawImage(video, 0, 0, canvas.width, canvas.height);

			// verify selfie
			const imagePixelsData =
				context && context.getImageData(0, 0, canvas.width, canvas.height);
			if (imagePixelsData?.data) {
				setIsValidSelfie(!checkIsImageBlank(imagePixelsData.data));
			}
			// Convert the canvas content to a data URL
			const imageData = canvas.toDataURL('image/jpeg');
			setSelfieimageURL(imageData);
			if (imageData.length > 15) {
				setActiveScreen('selfie-screen');
				setFaceMeshModelLoaded(false);
			}
			setSessionIdComplex(sessionId);
			if (faceEnrollmentCheck && !palmEnrollmentCheck) stopCamera();
		}
	};

	// Camera load more than 6sec
	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isFaceMeshModelLoaded) {
				setActiveScreen('liveness-information');
				setCameraPermission(false);
				setCameraPermissionMessage(CameraPermisionsErrors.Camera_time_out);
				// setSelfieLoaded(true);
			}
		}, 180000);

		// Cleanup the timeout if the component unmounts or `isLoaded` changes
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFaceMeshModelLoaded]);

	const handleCameraChange = (selectedOption: IOptionType) => {
		SetselectedDeviceIdState(selectedOption.value);
		SetselectedCameraLabel(selectedOption.label);
		setValueCamera(selectedOption);
	};
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const getWrapperClassName = useMemo(() => {
		if (androidVersionInfo.isBelowAndroid10 && isFaceMeshModelLoaded) {
			return '';
		} else return 'facial-wrapper-container';
	}, [androidVersionInfo.isBelowAndroid10, isFaceMeshModelLoaded]);

	return (
		<div>
			<div className={getWrapperClassName}>
				{!isFaceMeshModelLoaded ? (
					<FaceSetupModal />
				) : (
					<>
						<div
							className={`overlayDiv  ${
								moveLeftRef.current &&
								moveRightRef.current &&
								boundingBoxboolean
									? 'overlayDiv-border'
									: moveLeftRef.current && moveRightRef.current
										? 'overlayDiv-border-red'
										: ''
							}`}
							id="overlayDiv"
						>
							{moveLeftRef.current && moveRightRef.current ? (
								<></>
							) : (
								<>
									<div
										className={`right-part-move ${
											moveLeftRef.current ? 'success-rightMove' : ''
										}`}
									></div>
									<div
										className={`left-part-move ${
											moveRightRef.current ? 'success-rightMove' : ''
										}`}
									></div>
								</>
							)}
						</div>
						<div
							className={`${
								moveLeftRef.current && moveRightRef.current
									? 'info-container-selfie'
									: 'info-container'
							}`}
						>
							{/* <div className="long-arrow-right__facial-enroll"></div>   */}
							<div
								className={`info-instruction ${
									moveLeftRef.current && moveRightRef.current ? 'mb-2' : ''
								}`}
							>
								{!moveRightRef.current && !moveLeftRef.current && (
									<p className="info-text__facial-enroll"> Turn head Right </p>
								)}
								{moveRightRef.current && !moveLeftRef.current && (
									<p className="info-text__facial-enroll"> Turn head Left </p>
								)}
								{moveRightRef.current && moveLeftRef.current && (
									<p className="info-text__facial-enroll">
										{!boundingBoxboolean
											? 'Align your face in the square.'
											: 'Look forward and take a selfie'}
									</p>
								)}
							</div>
							{moveRightRef.current && moveLeftRef.current ? (
								<Button
									label={`Capture`}
									type="button__filled button__filled--primary button__large button__block mt-2 capture-btn-selfie"
									handleClick={() => {
										onCaptureselfie();
									}}
									disabled={!boundingBoxboolean}
								/>
							) : (
								cameraOptions.length > 1 && (
									<div className="camera-option__dropdown-container">
										<div className="camera-icon-box">
											<i className="ri-camera-fill camera-icon"></i>
										</div>
										<ReactDropdown
											options={cameraOptions}
											isRequired
											value={valueCamera}
											handleChangeSelect={handleCameraChange}
											defaultValue={deFaultvalueCamera}
											dropDownStyle={{ borderRadius: 'unset', border: 'unset' }}
											menuPlacement="top"
											optionDropDownStyle={optionCss}
											containerWidth="100%"
										/>
									</div>
								)
							)}
						</div>
					</>
				)}
				{androidVersionInfo.isBelowAndroid10 && (
					<video
						id="agroa-enroll"
						autoPlay
						playsInline
						className={` agora-video selfie-video-camera ${
							!isFaceMeshModelLoaded ? 'hide-video-webcam__facialEnroll' : ''
						}`}
						ref={agoraVideoRef}
					></video>
				)}
				{!androidVersionInfo.isBelowAndroid10 && (
					<video
						id="webcam"
						style={{
							position: 'absolute',
						}}
						className={`selfie-video-camera ${
							!isFaceMeshModelLoaded ? 'hide-video-webcam__facialEnroll' : ''
						}`}
						autoPlay
						playsInline
					></video>
				)}
				<CanvasWrapper />
			</div>
		</div>
	);
};
