import { Button, Image, Loader } from '@storybook';
import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import './selfie-detail.scss';

import { useNextStep, useSharedVariables } from 'hooks';
import {
	IsFaceMeshLoadedState,
	activeScreenState,
	isRetakeCameraState,
} from 'views/facial-enroll/states';
import { selfieImageURL } from 'views/facial-enroll/states/selfie-image-state';
import {
	IsValidSelfie,
	SessionIdComplex,
	SubmitBtnLoad,
} from 'views/selfie-camera/store';
import { SelfieDetail } from './constants/detail';
import { useFaceRecognitionRequests } from 'views/facial-enroll/hooks/hooks';
import {
	PalmEnrollmentEnabledState,
	faceEnrollmentEnabledState,
} from 'hooks/use-next-step/stores';
import usePeerConnection from '../web-rtc/connect-rtc';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';
import { useFace } from 'views/facial-enroll/hooks/use-face';
import { useAgoraVideoStream } from 'views/facial-enroll/hooks';

export const SelfieDetailPage = () => {
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const selfieImage = useRecoilValue(selfieImageURL);
	const sessionIdComplex = useRecoilValue(SessionIdComplex);
	// const { submitFaceRecognition } = useFaceRecognitionRequests();
	const { closePeerConnection } = usePeerConnection();
	const { handleNext, sessionDetails } = useNextStep();
	const submitBtnLoad = useRecoilValue(SubmitBtnLoad);
	const setIsRetakeCamera = useSetRecoilState(isRetakeCameraState);
	const setFaceMeshModelLoaded = useSetRecoilState(IsFaceMeshLoadedState);
	const { submitFaceRecognition } = useFaceRecognitionRequests();
	const faceEnrollmentCheck = useRecoilValue(faceEnrollmentEnabledState);
	const palmEnrollmentCheck = useRecoilValue(PalmEnrollmentEnabledState);
	const isValidSelfie = useRecoilValue(IsValidSelfie);
	const { stopPublishing, stopAgora, cleanupAgora } = useAgoraVideoStream();

	const { onboardingType, envHost } = useSharedVariables();
	const { biometricType } = useFace();
	const { trackEvents } = useTrackEvents();
	const palmDisableEnv = envHost === 'prod' || envHost === 'beta';

	const width = window.innerWidth;

	const isMobile = useMemo(() => {
		if (width <= 768) return true;
		return false;
	}, [width]);

	const handleSubmitSelfie = () => {
		if (sessionIdComplex || onboardingType === 'complex') {
			if ((faceEnrollmentCheck && !palmEnrollmentCheck) || palmDisableEnv) {
				//Reverting Palm Print for PreProp
				const imageDataWithoutPrefix = selfieImage.replace(
					/^data:image\/jpeg;base64,/,
					''
				);
				submitFaceRecognition(imageDataWithoutPrefix);
				stopPublishing();
				stopAgora();
				cleanupAgora();
				closePeerConnection();
			} else {
				setActiveScreen('palm-print');
			}
		} else {
			if (!envHost?.includes('stage')) {
				handleNext();
			}
		}
		trackEvents(EVENTS_TRACKING.BIOMETRICS_COMPLETED, {
			user_id: sessionDetails?.userId,
			biometric_type: biometricType,
			timestamp: timestamp,
		});
	};
	const renderSubmitLabel = useMemo(
		() => (submitBtnLoad ? <Loader type="loader" dimension={20} /> : 'Submit'),
		[submitBtnLoad]
	);
	return (
		<div className="selfieDetail--container">
			<div className="selfieDetail--container__wrapper">
				<div className="selfieDetail--container__content">
					<h2
						className={`selfieDetail--container__content__heading ${isMobile && 'mobile-heading'}`}
					>
						{isMobile ? SelfieDetail.SHORT_HEADING : SelfieDetail.HEADING}
					</h2>
					{!isMobile && isValidSelfie && (
						<p className="selfieDetail--container__content__subheading">
							{SelfieDetail.DESCRIPTION}
						</p>
					)}
					{!isValidSelfie && (
						<p className="selfieDetail--container__content__warning">
							{SelfieDetail.WARNING}
						</p>
					)}
				</div>

				{/* Picture for mobile view */}
				{isValidSelfie && isMobile && (
					<div className={`selfieDetail--container__selfie-mobile`}>
						<Image
							className="selfieDetail--container__imageinfo"
							url={selfieImage}
						/>
					</div>
				)}

				<div
					className={`${isMobile ? 'selfieDetail__button--info-mobile' : 'selfieDetail__button--info'} selfieDetail__button--sticky`}
				>
					<Button
						label={`Retake`}
						type={`button__filled button__filled--secondary button__large button__block mt-2 ${isMobile && 'mobile-button'}`}
						handleClick={() => {
							setActiveScreen('facial-enroll');
							setIsRetakeCamera(true);
							setFaceMeshModelLoaded(false);
						}}
						disabled={submitBtnLoad}
					/>
					<Button
						label={renderSubmitLabel}
						type={`button__filled button__filled--primary button__large button__block mt-2 ${isMobile && 'mobile-button'}`}
						handleClick={handleSubmitSelfie}
						disabled={!isValidSelfie || submitBtnLoad}
					/>
				</div>
			</div>

			{/* Picture for desktop view */}
			{!isMobile && (
				<div className="selfieDetail--container__selfie">
					<Image
						className="selfieDetail--container__imageinfo"
						url={selfieImage}
					/>
				</div>
			)}
		</div>
	);
};
