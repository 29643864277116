import env from '@beam-australia/react-env';

// Define default values for each environment
const ENV_DEFAULTS = {
	stage: {
		REACT_APP_GLARE_API: 'https://api.vouched.anandesh.dev/api/glare',
		REACT_APP_ENROLL_WEBRTC_URL: 'https://biometric.backendly.io',
		REACT_APP_KYC_DOMAIN: 'https://secure.stage.satschel.com',
		REACT_APP_LIQUIDITY_DOMAIN: 'https://exchange.stage.satschel.com',
		REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL:
			'https://rtc.live.cloudflare.com/v1/turn/keys/83ef201513b78effc6666f3143f6c9e6/credentials/generate',
		REACT_APP_NON_OF_ABOVE_HIDE:
			'["66d52883631827bda14965b3","66d9829f85248ebd90be1ca3","66d9828c85248ebd90be1c5c","66d526af3f629501587c7347","66eae8a714ae7a71b2afbe67"]',
		REACT_APP_VOUCHED_CAMERA: '!f-IhFf0G5KVs.jOJbtodjAQq*e~_I',
		REACT_APP_WEB_SOCKET: 'https://socket.stage.satschel.com',
		REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN:
			'7de4547dee84ea5e73f66664fdf2a8abc2911c2982aacbddd5973ef6361e8bc1',
		REACT_APP_FINIX_ENV_TYPE: 'sandbox',
		REACT_APP_GOOGLE_MAP_SECRET_TOKEN:
			'AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8',
		REACT_APP_LIQUIDITY_BUSINESSID: '66ceebd2b4cc63728593b8af',
		REACT_APP_GOOGLE_PAY_ENV_TYPE: '“TEST“',
		REACT_APP_SEGMENT_KEY: 'aNU3cRlE2o4yHqTrQuqF1CEwHUz8lvj8',
		REACT_APP_ENROLL_CUSTOM_SERVERS:
			'[{"urls":"turn:turn.cloudflare.com:3478?transport=udp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turn:turn.cloudflare.com:3478?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turns:turn.cloudflare.com:5349?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"stun:stun.cloudflare.com:3478"}]',
		REACT_APP_ESIGN_WEBCOMPONENT_URL:
			'https://esign.stage.satschel.com/web-component.stage.js',
		REACT_APP_SKIP_PHONE: 'false',
		REACT_APP_CDN_URL: 'https://storage.googleapis.com/satschel-assets-public',
		REACT_APP_API_HOST: 'https://api.stage.satschel.com/v2',
		REACT_APP_ESIGN_URL: 'https://esign.stage.satschel.com',
		REACT_APP_AGORA_ENROLL_API_HOST: 'https://biometric.backendly.io',
		REACT_APP_AGORA_ENROLL_API_ID: '967725ed771648708a01ce0058e99b96',
	},
	preprod: {
		REACT_APP_GLARE_API: 'https://api.vouched.anandesh.dev/api/glare',
		REACT_APP_ENROLL_WEBRTC_URL: 'https://api.pp.satschel.com/v2/webrtc',
		REACT_APP_KYC_DOMAIN: 'https://secure.pp.satschel.com',
		REACT_APP_LIQUIDITY_DOMAIN: 'https://exchange.pp.satschel.com',
		REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL:
			'https://rtc.live.cloudflare.com/v1/turn/keys/83ef201513b78effc6666f3143f6c9e6/credentials/generate',
		REACT_APP_NON_OF_ABOVE_HIDE:
			'["66d52883631827bda14965b3","66d9829f85248ebd90be1ca3","66d9828c85248ebd90be1c5c","66d526af3f629501587c7347"]',
		REACT_APP_VOUCHED_CAMERA: '!f-IhFf0G5KVs.jOJbtodjAQq*e~_I',
		REACT_APP_WEB_SOCKET: 'https://socket.pp.satschel.com',
		REACT_APP_FINIX_ENV_TYPE: 'sandbox',
		REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN:
			'7de4547dee84ea5e73f66664fdf2a8abc2911c2982aacbddd5973ef6361e8bc1',
		REACT_APP_GOOGLE_MAP_SECRET_TOKEN:
			'AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8',
		REACT_APP_GOOGLE_PAY_ENV_TYPE: '“TEST“',
		REACT_APP_LIQUIDITY_BUSINESSID: '66ceebd2b4cc63728593b8af',
		REACT_APP_SEGMENT_KEY: 'aNU3cRlE2o4yHqTrQuqF1CEwHUz8lvj8',
		REACT_APP_ENROLL_CUSTOM_SERVERS:
			'[{"urls":"turn:turn.cloudflare.com:3478?transport=udp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turn:turn.cloudflare.com:3478?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turns:turn.cloudflare.com:5349?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"stun:stun.cloudflare.com:3478"}]',
		REACT_APP_SKIP_PHONE: 'false',
		REACT_APP_ESIGN_WEBCOMPONENT_URL:
			'https://esign.pp.satschel.com/web-component.pp.js',
		REACT_APP_CDN_URL: 'https://storage.googleapis.com/satschel-assets-public',
		REACT_APP_API_HOST: 'https://api.pp.satschel.com/v2',
		REACT_APP_ESIGN_URL: 'https://esign.pp.satschel.com',
		REACT_APP_AGORA_ENROLL_API_HOST: 'https://biometric.backendly.io',
		REACT_APP_AGORA_ENROLL_API_ID: '967725ed771648708a01ce0058e99b96',
	},
	beta: {
		REACT_APP_GLARE_API: 'https://api.vouched.anandesh.dev/api/glare',
		REACT_APP_ENROLL_WEBRTC_URL: 'https://api.beta.satschel.com/v2/webrtc',
		REACT_APP_KYC_DOMAIN: 'https://secure.beta.simplici.io',
		REACT_APP_FS_URLS: "['https://secure.beta.simplici.io']",
		REACT_APP_LIQUIDITY_DOMAIN: 'https://exchange.beta.satschel.com',
		REACT_APP_NON_OF_ABOVE_HIDE:
			'["66d52883631827bda14965b3","66d9829f85248ebd90be1ca3","66d9828c85248ebd90be1c5c","66d526af3f629501587c7347"]',
		REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL:
			'https://rtc.live.cloudflare.com/v1/turn/keys/83ef201513b78effc6666f3143f6c9e6/credentials/generate',
		REACT_APP_VOUCHED_CAMERA: '!f-IhFf0G5KVs.jOJbtodjAQq*e~_I',
		REACT_APP_WEB_SOCKET: 'https://socket.beta.satschel.com',
		REACT_APP_FINIX_ENV_TYPE: 'sandbox',
		REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN:
			'7de4547dee84ea5e73f66664fdf2a8abc2911c2982aacbddd5973ef6361e8bc1',
		REACT_APP_GOOGLE_MAP_SECRET_TOKEN:
			'AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8',
		REACT_APP_GOOGLE_PAY_ENV_TYPE: '“TEST“',
		REACT_APP_LIQUIDITY_BUSINESSID: '66ceebd2b4cc63728593b8af',
		REACT_APP_SEGMENT_KEY: 'aNU3cRlE2o4yHqTrQuqF1CEwHUz8lvj8',
		REACT_APP_ENROLL_CUSTOM_SERVERS:
			'[{"urls":"turn:turn.cloudflare.com:3478?transport=udp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turn:turn.cloudflare.com:3478?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turns:turn.cloudflare.com:5349?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"stun:stun.cloudflare.com:3478"}]',
		REACT_APP_ESIGN_WEBCOMPONENT_URL:
			'https://esign.beta.simplici.io/web-component.beta.js',
		REACT_APP_SKIP_PHONE: 'false',
		REACT_APP_CDN_URL: 'https://storage.googleapis.com/satschel-public-assets',
		REACT_APP_API_HOST: 'https://api.beta.satschel.com/v2',
		REACT_APP_ESIGN_URL: 'https://esign.beta.simplici.io',
		REACT_APP_AGORA_ENROLL_API_HOST: 'https://biometric.backendly.io',
		REACT_APP_AGORA_ENROLL_API_ID: '967725ed771648708a01ce0058e99b96',
	},
	prod: {
		REACT_APP_GLARE_API: 'https://api.vouched.anandesh.dev/api/glare',
		REACT_APP_ENROLL_WEBRTC_URL: 'https://api.satschel.com/v2/webrtc',
		REACT_APP_KYC_DOMAIN: 'https://secure.simplici.io',
		REACT_APP_LIQUIDITY_DOMAIN: 'https://liquidity.io',
		REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL:
			'https://rtc.live.cloudflare.com/v1/turn/keys/83ef201513b78effc6666f3143f6c9e6/credentials/generate',
		REACT_APP_NON_OF_ABOVE_HIDE:
			'["66d52883631827bda14965b3","66d9829f85248ebd90be1ca3","66d9828c85248ebd90be1c5c","66d526af3f629501587c7347"]',
		REACT_APP_VOUCHED_CAMERA: '!f-IhFf0G5KVs.jOJbtodjAQq*e~_I',
		REACT_APP_WEB_SOCKET: 'https://socket.satschel.com',
		REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN:
			'7de4547dee84ea5e73f66664fdf2a8abc2911c2982aacbddd5973ef6361e8bc1',
		REACT_APP_FINIX_ENV_TYPE: 'live',
		REACT_APP_GOOGLE_MAP_SECRET_TOKEN:
			'AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8',
		REACT_APP_GOOGLE_PAY_ENV_TYPE: '“LIVE”',
		REACT_APP_LIQUIDITY_BUSINESSID: '66ceebd2b4cc63728593b8af',
		REACT_APP_SEGMENT_KEY: 'aNU3cRlE2o4yHqTrQuqF1CEwHUz8lvj8',
		REACT_APP_ENROLL_CUSTOM_SERVERS:
			'[{"urls":"turn:turn.cloudflare.com:3478?transport=udp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turn:turn.cloudflare.com:3478?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"turns:turn.cloudflare.com:5349?transport=tcp","username":"ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115","credential":"448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33"},{"urls":"stun:stun.cloudflare.com:3478"}]',
		REACT_APP_SKIP_PHONE: 'false',
		REACT_APP_ESIGN_WEBCOMPONENT_URL:
			'https://esign.simplici.io/web-component.js',
		REACT_APP_CDN_URL: 'https://storage.googleapis.com/satschel-public-assets',
		REACT_APP_API_HOST: 'https://api.satschel.com/v2',
		REACT_APP_ESIGN_URL: 'https://esign.simplici.io',
		REACT_APP_AGORA_ENROLL_API_HOST: 'https://biometric.backendly.io',
		REACT_APP_AGORA_ENROLL_API_ID: '967725ed771648708a01ce0058e99b96',
	},
};

export const REACT_APP_API_HOST = (window as any).API_HOST
	? (window as any).API_HOST
	: env('API_HOST');

const isStage = /stage/gi.test(REACT_APP_API_HOST);
const isPP = /pp/gi.test(REACT_APP_API_HOST);
const isProd = /api.satschel.com|api.simplici.io/gi.test(REACT_APP_API_HOST);
const isBeta = /beta/gi.test(REACT_APP_API_HOST);

const environmentHost = () => {
	if (isStage) return 'stage';
	if (isPP) return 'preprod';
	if (isProd) return 'prod';
	if (isBeta) return 'beta';
	return 'stage';
};

const envHost = environmentHost();

const DEFAULT_ENV = ENV_DEFAULTS[envHost as 'stage'];

export const REACT_APP_OCR_HOST = 'https://api-ocr.satschel.com/v1';

export const REACT_APP_CDN_URL =
	env('CDN_URL') || DEFAULT_ENV.REACT_APP_CDN_URL;
export const REACT_APP_GLARE_API =
	env('GLARE_API') || DEFAULT_ENV.REACT_APP_GLARE_API;
export const REACT_APP_LIQUIDITY_BUSINESSID =
	env('LIQUIDITY_BUSINESSID') || DEFAULT_ENV.REACT_APP_LIQUIDITY_BUSINESSID;
export const REACT_APP_SEGMENT_KEY =
	env('SEGMENT_KEY') || DEFAULT_ENV.REACT_APP_SEGMENT_KEY;
export const REACT_APP_GOOGLE_MAP_SECRET_TOKEN =
	env('GOOGLE_MAP_SECRET_TOKEN') ||
	DEFAULT_ENV.REACT_APP_GOOGLE_MAP_SECRET_TOKEN;
export const REACT_APP_ENROLL_WEBRTC_URL =
	env('ENROLL_WEBRTC_URL') || DEFAULT_ENV.REACT_APP_ENROLL_WEBRTC_URL;
export const REACT_APP_ENROLL_CUSTOM_SERVERS =
	env('ENROLL_CUSTOM_SERVERS') || DEFAULT_ENV.REACT_APP_ENROLL_CUSTOM_SERVERS;
export const REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL =
	env('ENROLL_CUSTOM_SERVERS_API_URL') ||
	DEFAULT_ENV.REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL;
export const REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN =
	env('ENROLL_CUSTOM_SERVERS_BEARER_TOKEN') ||
	DEFAULT_ENV.REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN;
export const REACT_APP_NON_OF_ABOVE_HIDE =
	env('NON_OF_ABOVE_HIDE') || DEFAULT_ENV.REACT_APP_NON_OF_ABOVE_HIDE;
export const REACT_APP_WEB_SOCKET =
	env('WEB_SOCKET') || DEFAULT_ENV.REACT_APP_WEB_SOCKET;
export const REACT_APP_LIQUIDITY_DOMAIN =
	env('LIQUIDITY_DOMAIN') || DEFAULT_ENV.REACT_APP_LIQUIDITY_DOMAIN;
export const REACT_APP_KYC_DOMAIN =
	env('KYC_DOMAIN') || DEFAULT_ENV.REACT_APP_KYC_DOMAIN;
export const REACT_APP_SKIP_PHONE =
	env('SKIP_PHONE') || DEFAULT_ENV.REACT_APP_SKIP_PHONE;
export const REACT_APP_FINIX_ENV_TYPE =
	env('FINIX_ENV_TYPE') || DEFAULT_ENV.REACT_APP_FINIX_ENV_TYPE;
export const REACT_APP_GOOGLE_PAY_ENV_TYPE =
	env('GOOGLE_PAY_ENV_TYPE') || DEFAULT_ENV.REACT_APP_GOOGLE_PAY_ENV_TYPE;
export const REACT_APP_AGORA_ENROLL_API_HOST =
	env('AGORA_ENROLL_API_HOST') || DEFAULT_ENV.REACT_APP_AGORA_ENROLL_API_HOST;
export const REACT_APP_AGORA_ENROLL_API_ID = env('AGORA_ENROLL_API_ID');
