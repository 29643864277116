import { Image } from '@storybook';
import { useCallback, useEffect, useMemo, PropsWithChildren, FC } from 'react';

import { useFullStoryTrackEvent, useNextStep, useSharedVariables } from 'hooks';
import { useSuccessReuest } from './stores';

import { FlowType } from 'constants/common';
import './success.scss';
import { useKycEvent } from 'views/kyc-events/store/hooks';
import { KYCEventsState } from 'views/kyc-events/store/states';
import { useRecoilValue } from 'recoil';
import { EventTicket } from 'views/kyc-events/components';
import { LOCATION_MODAL_KEY } from 'views/constants';
import { EVENTS_TRACKING, useTrackEvents } from 'helpers';

interface IActionButtonProps {
	showButton: boolean;
	className: string;
	ariaLabel: string;
	redirectUrl: string;
}

const ActionButtons: FC<PropsWithChildren<IActionButtonProps>> = ({
	showButton,
	className,
	ariaLabel,
	children,
	redirectUrl,
}) => {
	if (showButton) {
		return (
			<a className={className} aria-label={ariaLabel} href={redirectUrl}>
				{children}
			</a>
		);
	}
	return null;
};

export const Success = () => {
	const { submitSuccess } = useSuccessReuest();
	const { onboardingType ,configManager } = useSharedVariables();
	const { getEventTicket } = useKycEvent();
	const kycEventsState = useRecoilValue(KYCEventsState);

	const { sessionPayloadDetail, sessionDetails } = useNextStep();
	const { trackEvent } = useFullStoryTrackEvent();
	const { trackEvents: trackGAEvent } = useTrackEvents();

	const {
		customMessage,
		isAppStoreUrl,
		appStoreUrl,
		isPlayStoreUrl,
		playStoreUrl,
		isCustomBtnUrl,
		customRedirectUrl,
		customRedirectBtnText,
	} = (sessionPayloadDetail as any).currentAction?.metadata ?? {};

	const trackCompletedSession = useCallback(() => {
		// Destructure sessionDetails or default to an empty object if it's null or undefined
		const {
			name,
			pipelineId,
			billingType,
			organization,
			countryCode,
			phone,
			email,
			createdAt,
			code,
		} = sessionDetails ?? {};

		// Split the name into firstName and lastName using space (' ') as a delimiter
		const userName = name?.split(' ');

		// Construct properties object for tracking
		const properties = {
			user: {
				firstName: userName[0],
				lastName: userName[1],
				phone: `${countryCode} ${phone}`,
				email,
			},
			sessionCreatedAt: createdAt,
			sessionId: code,
			onboardingId: pipelineId,
			step: 'Completed',
			subscriptionType: billingType,
			businessName: organization?.name ?? '',
			// Determine onboarding flow type based on onboardingType
			onboardingFlow:
				onboardingType === FlowType.Complex
					? FlowType.Complex
					: FlowType.Linear,
			// Determine type based on onboardingType
			type: onboardingType === FlowType.QR ? FlowType.QR : FlowType.Invite,
			createdAt: new Date(), // Current timestamp
		};

		// Track the event 'session_completed' with the constructed properties
		trackEvent('session_completed', properties);
		trackGAEvent(EVENTS_TRACKING.VERIFICATION_COMPLETED, {
			sessionId: code,
			verification_method: 'captable',
			success: true,
		});
	}, [onboardingType, sessionDetails, trackEvent, trackGAEvent]); // Dependencies for useCallback

	useEffect(() => {
		submitSuccess();
		trackCompletedSession();
		if (kycEventsState?._id) {
			getEventTicket();
		}
		window.addEventListener('beforeunload', () => {
			localStorage.removeItem(LOCATION_MODAL_KEY);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {image , description = customMessage || '' , title = 'Verification Completed'} = configManager?.pages?.success ?? {}
	const imageProps = useMemo(()=> image ?  {url: image} : {fileName:"success.gif"},[image])
	
	const getComponent = useMemo(() => {
		if (kycEventsState?._id) return <EventTicket />;
		return (
			<div className="success-wrapper">
				<div className="success-wrapper__greetings">
					<Image
					     {...imageProps}
						className="success-wrapper__success-icon"
					/>
					<h3 className="success-wrapper__greetings-message">
						{title}
					</h3>
				</div>
				<p className="success-wrapper__custom-message">{description}</p>
				<div className="success-wrapper__actions">
					<ActionButtons
						showButton={isAppStoreUrl}
						className="success-wrapper__app-store-action"
						ariaLabel="Open App Store"
						redirectUrl={appStoreUrl}
					>
						<Image fileName="appstore.svg" className="success-wrapper__store" />
					</ActionButtons>
					<ActionButtons
						showButton={isPlayStoreUrl}
						className="success-wrapper__app-store-action"
						ariaLabel="Open Play Store"
						redirectUrl={playStoreUrl}
					>
						<Image
							fileName="googleplay.svg"
							className="success-wrapper__store"
						/>
					</ActionButtons>
					<ActionButtons
						showButton={isCustomBtnUrl}
						className="success-wrapper__custom-explore"
						ariaLabel="Redirect to custom url"
						redirectUrl={customRedirectUrl}
					>
						{customRedirectBtnText || 'Explore'}
					</ActionButtons>
				</div>
			</div>
		);
	}, [kycEventsState?._id, imageProps, title, description, isAppStoreUrl, appStoreUrl, isPlayStoreUrl, playStoreUrl, isCustomBtnUrl, customRedirectUrl, customRedirectBtnText]);

	return getComponent;
};
