import { atom } from 'recoil';

type IKYBFormStateName =
	| 'name'
	| 'website'
	| 'streetAddress'
	| 'country'
	| 'city'
	| 'state'
	| 'postal_code'
	| 'fein'
	| 'phone'
	| 'firstName'
	| 'lastName'
	| 'linkedInUrl'
	| 'date_of_birth'
	| 'national_id_number';

export interface IKYBFormState {
	label: string;
	placeHolder: string;
	type: 'text' | 'dropdown' | 'auto-fill' | 'phone' | 'date';
	name: IKYBFormStateName;
}
interface IOnnerDetails {
	firstName: string;
	lastName: string;
}

export interface IOptionType {
	label: string;
	value: string;
}

export interface IMemberData {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	countryCode: string;
	is_kyc_aml_request: boolean;
}

//Gaurav : complex multiple representative state interface.
export type IRepresentativeNameType =
	| 'first_name'
	| 'last_name'
	| 'date_of_birth'
	| 'national_id_number'
	| 'telephone_number'
	| 'country_code'
	| 'street1'
	| 'city'
	| 'state'
	| 'zip';

export interface IMultipleRepresentativeDetailsState {
	first_name: string;
	last_name: string;
	// date_of_birth: string;
	// national_id_number: string;
	telephone_number: string;
	street1?: string;
	street2?: string;
	city?: string;
	state?: string;
	zip?: string;
	email?: string;
	ownershipPercentage?: string;
	type?: string;
}

export interface IRepresentativeDataThroughKyc {
	first_name: string;
	last_name: string;
	date_of_birth: string;
	national_id_number: string;
	telephone_number: string;
	country_code: string;
}
export interface IMultipleRepresentativeDetailsErrorState {
	first_name: boolean;
	last_name: boolean;
	date_of_birth: boolean;
	national_id_number: boolean;
	telephone_number: boolean;
	street1?: boolean;
	street2?: boolean;
	city?: boolean;
	state?: boolean;
	zip?: boolean;
}

export const OwnerDetailsState = atom<IOnnerDetails>({
	key: 'set-Owner-Details',
	default: {
		firstName: '',
		lastName: '',
	},
});

//Gaurav : complex multiple representative state.
export const MultipleRepresentativeDetailsState = atom<
	IMultipleRepresentativeDetailsState[] | any[]
>({
	key: 'multiple-representative-details-state',
	default: [
		// may be needed in future
		// {
		// 	first_name: '',
		// 	last_name: '',
		// 	date_of_birth: '',
		// 	national_id_number: '',
		// 	telephone_number: '',
		// 	country_code: '',
		// 	// Arun kumar : Need to hide for address in represnatative information Note* please don;t remove might be in future use
		// 	// street1: "",
		// 	// city: "",
		// 	// state: "",
		// 	// zip: "",
		// },
	],
});

// Arun Kumar: State for storing the value for suplimental case for KYB
export const IsKybSupplimentalTrue = atom<boolean>({
	key: 'is-suplimental-for-kyb-true',
	default: false,
});

export const isMultipleRepresentativeState = atom<boolean>({
	key: 'is-multiple-representative-state',
	default: false,
});

// THE KYB RELEATED RECOILS RECOILS
export type IKyb_Provider = 'THE_KYB' | 'Lexis_Nexis';

export const KybProviderTypeState = atom<IKyb_Provider>({
	key: 'kyb-provider-type-state',
	default: 'THE_KYB',
});

// ARUN KUMAR :- Note *:  If you want to run the KYB flow for THE KYB please add default value "THE_KYB" for KybProviderTypeState and "buisness_verification" for KybBodyStepsState
export type IKyb_Step =
	| 'buisness_verification'
	| 'select_company'
	| 'add-your-company'
	| 'kyb-loading-screen'
	| 'select_member'
	| 'select_subsidiary'
	| '';

type KYBCompanyFlow = 'manual' | 'select';

export const KybBodyStepsState = atom<IKyb_Step>({
	key: 'kyb-body-steps-state',
	default: 'buisness_verification',
});

export const IsBuisnessSearchGotTimeOutState = atom<boolean>({
	key: 'is-buisness-search-got-time-out-error-state',
	default: false,
});
export interface IBuisness_information {
	[key: string]: string | undefined;
}

export const BusinessInformationState = atom<IBuisness_information | any>({
	key: 'business-information-state',
	default: {
		companyName: '',
		fein: '',
		country: '',
		state: '',
	},
});

export const TheKybSubmitLoadingState = atom<boolean>({
	key: 'the-kyb-loading--state',
	default: false,
});

export const TheKybSearchIntervalState = atom<boolean>({
	key: 'the-kyb-search-interval-state',
	default: false,
});
export const SelectedCompanyForKybState = atom<any>({
	key: 'selected-company-kyb-state',
	default: {},
});
export const BuisnessCompanyListState = atom<any>({
	key: 'buisness-company-list-state',
	default: {
		status: 'completed',
		companies: [],
		_id: '',
	},
});

export const IsBuisnessListFetchedState = atom<boolean>({
	key: 'is-buisness-list-fetched-key',
	default: false,
});

export const RepresentativeFilledToggleState = atom<boolean>({
	key: 'representative-filled-toggle-state-key',
	default: false,
});

export const RepresentativeDataThroughKyc = atom<IRepresentativeDataThroughKyc>(
	{
		key: '',
		default: {
			first_name: '',
			last_name: '',
			telephone_number: '',
			date_of_birth: '',
			country_code: '',
			national_id_number: '',
		},
	}
);

export interface CountryStateList {
	name: string;
	states: { name: string; state_code: string }[];
	iso3: string;
	iso2: string;
	phone_code: string;
	currency: string;
	currency_name: string;
	emoji: string;
	currency_symbol: string;
	documents?: { name: string; noOfSides: string }[];
	code?: string;
	kyb: { type: string; country: boolean; states: boolean };
}

export const CountryStateListState = atom<CountryStateList[]>({
	key: 'country-state-list-for-kyb',
	default: [],
});

interface StateListKyb {
	label: string;
	value: string;
}
export const TheKYBStateListState = atom<StateListKyb[]>({
	key: 'state-list-arry-state-kyb',
	default: [],
});

export const TheKYBCountryListState = atom<StateListKyb[]>({
	key: 'country-list-arry-state-kyb',
	default: [],
});

export interface ICompanyMemberList {
	designation: string;
	id: string;
	name: string;
	status: string;
	date_of_birth: { day: string; month: string; year: string };
	[key: string]: string | object;
	type: { label: string; value: string };
	ownershipPercentage: string;
}

export interface IKYBCompanyMemberListState {
	activeIndex: number | null;
	members: ICompanyMemberList[];
}

interface IKycAmlToogleAction {
	isFreshKycLink: boolean;
	doKyc: boolean;
	id: number;
}

export interface IKycAMlCompanyMemberList {
	designation: string;
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	status: string;
	date_of_birth: { day: string; month: string; year: string };
	[key: string]: string | object;
}

export interface IKycAmlMember {
	activeIndex?: { [key: number | string]: boolean };
	members: ICompanyMemberList[];
}

export interface IKycAmlMemberForm {
	formType: 'exist_member' | 'custom_member';
	inputType: { [key: string]: string }[];
}

export interface IAddress {
	StreetNumber?: string;
	StreetName?: string;
	StreetSuffix?: string;
	StreetPostDirection?: string;
	City?: string;
	State?: string;
	Zip5?: string;
	Zip4?: string;
	County?: string;
}

export interface ISubsidiariesBusiness {
	logoType: string;
	TIN: string;
	name: string;
	Address: IAddress | string;
	extra: any;
	id?: string;
}

export const TheKYBCompanyMemberListState = atom<IKYBCompanyMemberListState>({
	key: 'kyb-company-member-list-state',
	default: { activeIndex: null, members: [] },
});

export const KYBCompanyFlowState = atom<KYBCompanyFlow>({
	key: 'kyb-company-flow-state',
	default: 'select',
});

/* this states store KYC AML verification toogle actions */
export const KycAmlVerificationToggleActionState = atom<IKycAmlToogleAction[]>({
	key: 'kyc-aml-verification-toggle-action-state',
	default: [
		{
			isFreshKycLink: true,
			doKyc: true,
			id: 0,
		},
	],
});

/* this state store the company member selected for KYC-AML verification */
export const TheKYBCompanyMemberForKycAmlVerification = atom<{
	index: number[];
	id: string[];
	isError: boolean;
}>({
	key: 'kyb-company-member-kyc-aml-verification',
	default: { index: [], id: [], isError: false },
});

/* this state store the company member for KYC-AML verification list */
export const TheKYBCompanyMemberForKycAmlVerificationListState =
	atom<IKycAmlMember>({
		key: 'kyb-company-member-kyc-aml-list-state',
		default: { activeIndex: {}, members: [] },
	});

export const TheKYBCompanySelectedMemberErrorState = atom<{
	[key: string]: boolean;
}>({
	key: 'the-kyb-company-selected-member-error-state',
	default: {},
});

export const SubsidiaryBusinessListState = atom<ISubsidiariesBusiness[]>({
	key: 'subsidiary-business-list-state',
	default: [],
});

export const SelectedSubsidiaryBusinessDetailState = atom<
	ISubsidiariesBusiness[]
>({
	key: 'selected-subsidiary-business-details-state',
	default: [],
});

export const SubsidiaryBusinessLoadingState = atom<boolean>({
	key: 'subsidiary-business-loading-state',
	default: false,
});

// this states are only for the kyb search input
export const SearchKybCompanyInputState = atom<string>({
	key: 'search-kyb-company-input-state',
	default: '',
});

export const SearchKybCompanyListState = atom<any>({
	key: 'search-kyb-company-list-state',
	default: {
		status: 'completed',
		companies: [],
		_id: '',
	},
});

export const SearchKybCompanyMembersInputState = atom<string>({
	key: 'search-kyb-company-members-state',
	default: '',
});

export const SearchKybCompanyMembersListState = atom<IKycAmlMember>({
	key: 'search-kyb-company-member-list-state',
	default: { activeIndex: {}, members: [] },
});

export const TheKybMemberListLoader = atom<boolean>({
	key: 'the-Kyb-member-list-Loader',
	default: false,
});

export const IsShowOwnerShipOptionKybMemberListState = atom<boolean>({
	key: 'is-show-ownership-option-kyb-member-list-state',
	default: false,
});
